import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Grid, GridSize } from '@breathelife/mui';

import { PdfFieldText, PdfLabelText, PdfValueText } from '../Typography';

type Props = {
  value: string;
  fieldText?: string;
  labelText?: string;
  testID?: string;
  isSignature?: boolean;
  isOptionGroup?: boolean;
  isUnselectedOption?: boolean;
  valueWeight?: number;
  alignLabelWithValue?: boolean;
  fieldWidth?: GridSize;
  labelWidth?: GridSize;
  valueWidth?: GridSize;
};

const PdfResizedGridContainer = styled(Grid)<{ isOptionGroup?: boolean }>`
  &&&& {
    ${({ isOptionGroup }) => {
      return isOptionGroup
        ? css`
            margin-bottom: 8px;
          `
        : '';
    }}
  }
`;

const eSignatureStyling = css`
  padding: 3px 0px 0px 20px;
  display: block;
  height: 40px;
`;

const greyOutUnselectedOption = css`
  opacity: 40%;
`;

const ValueContainer = styled.div<{ isSignature?: boolean; isUnselectedOption?: boolean }>`
  padding: 6px 8px 6px 8px;
  height: auto;
  background: #f6f6f6;
  display: flex;
  justify-content: left;
  align-items: center;
  ${({ isSignature }) => {
    return isSignature ? eSignatureStyling : '';
  }}
  ${({ isUnselectedOption }) => {
    return isUnselectedOption ? greyOutUnselectedOption : '';
  }}
`;

export function PdfQuestionAnswerGroup(props: Props): ReactElement {
  const {
    fieldText,
    value,
    labelText,
    valueWeight = 400,
    isSignature = false,
    isOptionGroup = false,
    isUnselectedOption = false,
    alignLabelWithValue = false,
    fieldWidth = 12,
    labelWidth = 12,
    valueWidth = 12,
    testID = '',
  } = props;

  return (
    <PdfResizedGridContainer container isOptionGroup={isOptionGroup}>
      {fieldText && (
        <Grid item xs={fieldWidth}>
          <PdfFieldText>{ReactHtmlParser(fieldText)}</PdfFieldText>
        </Grid>
      )}
      {labelText && (
        <Grid className={'labelText'} item xs={labelWidth}>
          <PdfLabelText alignLabelWithValue={alignLabelWithValue}>{ReactHtmlParser(labelText)}</PdfLabelText>
        </Grid>
      )}
      <Grid className={'valueText'} item xs={valueWidth}>
        <ValueContainer isSignature={isSignature} isUnselectedOption={isUnselectedOption}>
          <PdfValueText weight={valueWeight} isSignature={isSignature} data-testid={testID}>
            {value}
          </PdfValueText>
        </ValueContainer>
      </Grid>
    </PdfResizedGridContainer>
  );
}
