import { Box, FormLabel, RadioGroup as MuiRadioGroup, Typography as MuiTypography } from '@breathelife/mui';

import ReactHtmlParser from 'html-react-parser';
import { ReactNode, ReactElement, isValidElement, Fragment } from 'react';

import { StyledFormControlLabel, StyledRadio, RadioLabel, StyledFormControl } from './Styles';

type RadioGroupProps<T = string> = {
  name: string;
  id?: string;
  label?: string;
  subtitle?: string;
  value?: T;
  onChange: (value: T) => void;
  children: ReactNode;
  required?: boolean;
};

type RadioProps<T = string> = {
  label: string | ReactElement | null;
  value: T;
  disabled?: boolean;
  className?: string;
  hasError?: boolean;
  required?: boolean;
  name: string;
  id?: string;
};

export function SimpleRadio<T extends string>(props: RadioProps<T>): ReactElement {
  const isLabelAnElement = isValidElement(props.label);

  return (
    <StyledFormControlLabel
      value={props.value}
      control={<StyledRadio id={props.id} name={props.name} required={props.required} color='primary' />}
      label={
        <MuiTypography className={props.className}>
          <RadioLabel className={props.className} grey={props.disabled ? 60 : 90} fontSize={'14px'}>
            {isLabelAnElement ? props.label : null}
            {!isLabelAnElement && props.label ? ReactHtmlParser(props.label as string) : null}
          </RadioLabel>
        </MuiTypography>
      }
      disabled={props.disabled}
      className={props.className}
      $hasError={!!props.hasError}
    />
  );
}

export function SimpleRadioGroup<T extends string>(props: RadioGroupProps<T>): ReactElement {
  return (
    // @ts-ignore seems like styled component is not getting the proper typings from material
    <StyledFormControl component='fieldset'>
      {props.label && (
        <Box mb={2}>
          <FormLabel component='legend'>
            <MuiTypography>
              <RadioLabel grey={90} fontSize={'15px'}>
                {ReactHtmlParser(props.label)}
              </RadioLabel>
            </MuiTypography>

            {props.subtitle && (
              <Fragment>
                <br />
                <RadioLabel grey={60} fontSize={'15px'}>
                  {ReactHtmlParser(props.subtitle)}
                </RadioLabel>
              </Fragment>
            )}
          </FormLabel>
        </Box>
      )}
      <MuiRadioGroup
        onChange={(event) => props.onChange(event.target.value as T)}
        // We want to have a controlled radio group, but we might have an undefined value at start
        // to force a user to select a value. Without this empty string, a warning will be displayed in the console
        // saying that we are trying to change an uncontrolled radio group to a controlled radio group.
        value={props.value || ''}
        aria-label={props.label}
        aria-required={props.required}
        name={props.name}
        id={props.id}
      >
        {props.children}
      </MuiRadioGroup>
    </StyledFormControl>
  );
}
