import { ReactElement, SVGProps } from 'react';
import styled from 'styled-components';

import { InfoVariants } from '@breathelife/questionnaire-engine';

export type IconVerticalAlignment = 'center' | 'top' | 'bottom';
export type BorderStyle = 'rounded' | 'straight';

type ContainerProps = {
  variant?: InfoVariants;
  borderStyle: BorderStyle;
};

type IconProps = { verticalAlignment: IconVerticalAlignment };

export function ConfirmationIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg width='36' height='36' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M24.0001 39.142C32.3628 39.142 39.1421 32.3627 39.1421 24C39.1421 15.6373 32.3628 8.85805 24.0001 8.85805C15.6374 8.85805 8.85815 15.6373 8.85815 24C8.85815 32.3627 15.6374 39.142 24.0001 39.142Z'
        fill='#ECF5EB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 9.71609C16.1112 9.71609 9.71609 16.1112 9.71609 24C9.71609 31.8888 16.1112 38.2839 24 38.2839C31.8888 38.2839 38.2839 31.8888 38.2839 24C38.2839 16.1112 31.8888 9.71609 24 9.71609ZM8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24Z'
        fill='#39B54A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.2394 18.3814C32.7291 18.7506 32.8269 19.4468 32.4577 19.9365L25.0074 29.8203C24.7458 30.166 24.4131 30.4514 24.032 30.6578C23.651 30.8642 23.2302 30.9867 22.798 31.0175C22.3658 31.0483 21.9319 30.9866 21.5254 30.8365C21.119 30.6864 20.7491 30.4512 20.4409 30.1464L20.4386 30.1441L16.5923 26.3178C16.1575 25.8853 16.1557 25.1822 16.5882 24.7474C17.0207 24.3127 17.7238 24.3108 18.1586 24.7433L22.0026 28.5674C22.0029 28.5677 22.0032 28.568 22.0035 28.5683C22.0859 28.6496 22.1852 28.7127 22.2949 28.7532C22.405 28.7939 22.5228 28.8107 22.6402 28.8023C22.7576 28.794 22.8716 28.7607 22.9745 28.7049C23.0771 28.6494 23.1661 28.5729 23.2359 28.481C23.2361 28.4807 23.2357 28.4813 23.2359 28.481L30.6843 18.5997C31.0534 18.11 31.7497 18.0123 32.2394 18.3814Z'
        fill='#39B54A'
      />
    </svg>
  );
}

export function ErrorIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg width='36' height='36' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        opacity='0.1'
        d='M24 38.1956C31.84 38.1956 38.1956 31.84 38.1956 24C38.1956 16.16 31.84 9.80441 24 9.80441C16.16 9.80441 9.80444 16.16 9.80444 24C9.80444 31.84 16.16 38.1956 24 38.1956Z'
        fill='#B70000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 10.6088C16.6043 10.6088 10.6088 16.6043 10.6088 24C10.6088 31.3957 16.6043 37.3912 24 37.3912C31.3957 37.3912 37.3912 31.3957 37.3912 24C37.3912 16.6043 31.3957 10.6088 24 10.6088ZM9 24C9 15.7157 15.7157 9 24 9C32.2843 9 39 15.7157 39 24C39 32.2843 32.2843 39 24 39C15.7157 39 9 32.2843 9 24Z'
        fill='#B70000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.3174 24.0007L18.0445 19.7279C17.5725 19.2558 17.5725 18.4904 18.0445 18.0183C18.5166 17.5462 19.282 17.5462 19.7541 18.0183L24.027 22.2912L28.3027 18.0155C28.7747 17.5434 29.5402 17.5434 30.0122 18.0155C30.4843 18.4876 30.4843 19.253 30.0122 19.7251L25.7366 24.0007L30.0117 28.2759C30.4838 28.7479 30.4838 29.5134 30.0117 29.9855C29.5396 30.4575 28.7742 30.4575 28.3021 29.9855L24.027 25.7103L19.7547 29.9827C19.2826 30.4548 18.5172 30.4548 18.0451 29.9827C17.573 29.5106 17.573 28.7452 18.0451 28.2731L22.3174 24.0007Z'
        fill='#B70000'
      />
    </svg>
  );
}

export function WarningIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 32.25C25.8701 32.25 32.25 25.8701 32.25 18C32.25 10.1299 25.8701 3.75 18 3.75C10.1299 3.75 3.75 10.1299 3.75 18C3.75 25.8701 10.1299 32.25 18 32.25ZM18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z'
        fill='#F6F6F6'
      />
      <path
        d='M12.7003 29.3565H23.2997L29.3565 23.2997V12.7003L23.2997 6.64355H12.7003L6.64355 12.7003V23.2997L12.7003 29.3565Z'
        fill='#DE7F30'
        opacity='0.1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.2453 6.18849C12.366 6.0678 12.5296 6 12.7003 6H23.2997C23.4704 6 23.634 6.0678 23.7547 6.18849L29.8115 12.2453C29.9322 12.366 30 12.5296 30 12.7003V23.2997C30 23.4704 29.9322 23.634 29.8115 23.7547L23.7547 29.8115C23.634 29.9322 23.4704 30 23.2997 30H12.7003C12.5296 30 12.366 29.9322 12.2453 29.8115L6.18849 23.7547C6.0678 23.634 6 23.4704 6 23.2997V12.7003C6 12.5296 6.0678 12.366 6.18849 12.2453L12.2453 6.18849ZM12.9669 7.28707L7.28707 12.9669V23.0331L12.9669 28.7129H23.0331L28.7129 23.0331V12.9669L23.0331 7.28707H12.9669Z'
        fill='#DE7F30'
      />
      <path
        d='M13.5276 18.6066C13.5276 18.6067 13.5276 18.6067 13.5276 18.6067C13.7881 18.7831 14.1247 18.8013 14.4026 18.6539C14.6806 18.5065 14.8544 18.2176 14.8544 17.903L14.0044 17.903L14.8544 17.903V17.9029V17.9024V17.9006L14.8544 17.8934L14.8544 17.8652L14.8544 17.7577L14.8544 17.3704L14.8544 16.161L14.8544 14.0422C14.8544 13.6779 14.9426 13.318 15.1129 12.9921L14.4034 12.6214L15.1129 12.9921C15.2832 12.6661 15.5311 12.3827 15.8375 12.1663C16.1439 11.95 16.4997 11.8073 16.8757 11.7512C17.2517 11.6951 17.6359 11.7274 17.996 11.8453C18.1962 11.9108 18.667 12.0645 19.1973 12.2376C19.9609 12.4869 20.848 12.7765 21.2281 12.901C21.7048 13.0572 22.1163 13.3553 22.4062 13.75C22.696 14.1444 22.8505 14.616 22.8504 15.098C22.8502 15.7627 22.8503 20.104 22.8504 21.1568C22.8505 21.9784 22.5181 22.77 21.9204 23.3569C21.3206 23.9436 20.5042 24.2759 19.6495 24.2756C19.0071 24.2754 18.5647 24.2755 18.1368 24.2756C17.8491 24.2756 17.5679 24.2757 17.2369 24.2756C16.5492 24.2756 15.8729 24.1091 15.2682 23.792C14.6783 23.4826 14.2198 23.033 13.5487 22.346C13.086 21.8725 12.9029 21.6302 12.5193 21.1227C12.4244 20.9972 12.3173 20.8554 12.1906 20.6899C12.0976 20.5684 11.9876 20.4362 11.8829 20.3105L11.8763 20.3025C11.7698 20.1746 11.6623 20.0455 11.5549 19.9096C11.3334 19.6292 11.1374 19.3507 11.0026 19.079C10.8671 18.8058 10.8199 18.5917 10.8304 18.4265C10.8394 18.2862 10.893 18.1105 11.1136 17.8939C11.3478 17.6638 11.5212 17.6419 11.6912 17.6623C11.9311 17.6911 12.2132 17.8183 12.6026 18.0324C12.8004 18.1411 13.0298 18.283 13.2167 18.4027C13.3086 18.4616 13.3872 18.5132 13.4426 18.5499L13.5066 18.5925L13.5229 18.6035L13.5268 18.6061L13.5275 18.6066C13.5275 18.6066 13.5276 18.6066 13.5276 18.6066Z'
        fill='white'
        stroke='#DE7F30'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function InformationIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg width='36' height='36' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M24.0001 39.142C32.3628 39.142 39.1421 32.3627 39.1421 24C39.1421 15.6373 32.3628 8.85805 24.0001 8.85805C15.6374 8.85805 8.85815 15.6373 8.85815 24C8.85815 32.3627 15.6374 39.142 24.0001 39.142Z'
        fill='#EDF0F9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 9.71609C16.1112 9.71609 9.71609 16.1112 9.71609 24C9.71609 31.8888 16.1112 38.2839 24 38.2839C31.8888 38.2839 38.2839 31.8888 38.2839 24C38.2839 16.1112 31.8888 9.71609 24 9.71609ZM8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24Z'
        fill='#2B4177'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.2394 18.3814C32.7291 18.7506 32.8269 19.4468 32.4577 19.9365L25.0074 29.8203C24.7458 30.166 24.4131 30.4514 24.032 30.6578C23.651 30.8642 23.2302 30.9867 22.798 31.0175C22.3658 31.0483 21.9319 30.9866 21.5254 30.8365C21.119 30.6864 20.7491 30.4512 20.4409 30.1464L20.4386 30.1441L16.5923 26.3178C16.1575 25.8853 16.1557 25.1822 16.5882 24.7474C17.0207 24.3127 17.7238 24.3108 18.1586 24.7433L22.0026 28.5674C22.0029 28.5677 22.0032 28.568 22.0035 28.5683C22.0859 28.6496 22.1852 28.7127 22.2949 28.7532C22.405 28.7939 22.5228 28.8107 22.6402 28.8023C22.7576 28.794 22.8716 28.7607 22.9745 28.7049C23.0771 28.6494 23.1661 28.5729 23.2359 28.481C23.2361 28.4807 23.2357 28.4813 23.2359 28.481L30.6843 18.5997C31.0534 18.11 31.7497 18.0123 32.2394 18.3814Z'
        fill='#2B4177'
      />
    </svg>
  );
}

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['borderStyle', 'variant'].includes(prop),
})<ContainerProps>`
  background: #fbfbfb;
  display: flex;
  padding: 16px;

  border: 1px solid ${(props) => (props.variant ? props.theme.colors[props.variant] : props.theme.colors.grey[50])};
  border-radius: ${(props) => (props.borderStyle === 'rounded' ? '4px' : '0px')};

  margin: 8px;
  width: 100%;

  span {
    padding-left: 8px;
  }

  strong {
    display: block;
    padding-bottom: 8px;
  }

  p {
    font-size: 14px;
    margin: 0;
    line-height: 18px;
  }

  svg {
    margin-top: -4px;
    flex-shrink: 0;
  }
`;

export const IconContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['verticalAlign'].includes(prop),
})<IconProps>`
  display: flex;
  align-items: ${(props) =>
    (props.verticalAlignment === 'center' && 'center') ||
    (props.verticalAlignment === 'top' && 'flex-start') ||
    (props.verticalAlignment === 'bottom' && 'flex-end') ||
    'inherit'};
  padding-right: ${(props) => (props.verticalAlignment === 'center' && '8px') || '0px'};
`;

export const TextContainer = styled.span`
  display: flex;
  gap: 4px;

  strong {
    display: inline-block;
  }
`;
