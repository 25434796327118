import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { Collapse, List as MuiList } from '@breathelife/mui';
import { Permission } from '@breathelife/types';

import { SharedTheme } from '../../Theme';
import { Icon, IconProps } from '../Icon/Icon';
import { ProducerTypography, TypographyComponents, TypographyVariants } from '../ProducerTypography/ProducerTypography';

import { RestrictedToPermissions } from '../Restricted/RestrictedToPermissions';

import {
  ICON_SIZE,
  ListItemContentWithLeftPadding,
  ListItemIconWithoutMinWidth,
  SelectedListItemLeftBar,
  StyledListItem,
} from './styles';

export type ProducerNavigationItemData = {
  identifier: string;
  text?: string;
  icon?: IconProps;
  children?: ProducerNavigationItemData[];
  permissions?: Permission[];
  onClick?: () => void;
};

type ListItemProps = {
  level: number;
  selected?: boolean;
  childIsSelected?: boolean;
  onClick?: (identifier: string) => void;
};

function ProducerNavigationListItem({
  text,
  icon,
  selected,
  childIsSelected,
  level,
  onClick,
  identifier,
}: Omit<ProducerNavigationItemData, 'children'> & ListItemProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <StyledListItem
      button
      onClick={() => onClick?.(identifier)}
      disableGutters
      selected={selected}
      disableRipple
      disableTouchRipple
    >
      <SelectedListItemLeftBar visible={Boolean(selected)} />
      <ListItemContentWithLeftPadding level={level}>
        {icon && (
          <ListItemIconWithoutMinWidth>
            <Icon
              {...icon}
              size={icon.size || `${ICON_SIZE}px`}
              color={{
                primary: selected || childIsSelected ? undefined : theme.colors.grey[70],
                secondary: selected || childIsSelected ? undefined : theme.colors.grey[30],
              }}
            />
          </ListItemIconWithoutMinWidth>
        )}

        <ProducerTypography
          variant={TypographyVariants.body3}
          component={TypographyComponents.span}
          color={selected ? theme.colors.primary.default : theme.colors.grey[level === 0 ? 80 : 70]}
        >
          {text}
        </ProducerTypography>
      </ListItemContentWithLeftPadding>
    </StyledListItem>
  );
}

function ProducerNavigationListItemAndChildren({
  children,
  selectedItemKey,
  onClick,
  automaticallyOpenParentOfSelected,
  applicablePermissions,
  ...itemData
}: ProducerNavigationItemData &
  ListItemProps & {
    selectedItemKey?: string;
    automaticallyOpenParentOfSelected?: boolean;
    applicablePermissions: Permission[];
  }): ReactElement {
  const childIsSelected = useMemo(
    () =>
      children?.some((child) => {
        return child.identifier === selectedItemKey;
      }),
    [children, selectedItemKey],
  );

  const [isOpen, setIsOpen] = useState<boolean>(automaticallyOpenParentOfSelected ? Boolean(childIsSelected) : false);

  useEffect(() => {
    if (automaticallyOpenParentOfSelected) {
      setIsOpen(Boolean(childIsSelected));
    }
  }, [automaticallyOpenParentOfSelected, childIsSelected]);

  const handleClick = useCallback(() => {
    onClick?.(itemData.identifier);
    setIsOpen((prev) => !prev);
  }, [setIsOpen, onClick, itemData.identifier]);

  return (
    <Fragment>
      <ProducerNavigationListItem
        {...itemData}
        onClick={handleClick}
        selected={selectedItemKey === itemData.identifier}
        childIsSelected={childIsSelected}
      />
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <MuiList component='div' disablePadding>
          {children?.map((item) => {
            return (
              <RestrictedToPermissions
                key={item.identifier}
                applicablePermissions={applicablePermissions}
                requiredPermissions={item.permissions}
              >
                <ProducerNavigationListItem
                  {...item}
                  onClick={item.onClick}
                  level={itemData.level + 1}
                  selected={selectedItemKey === item.identifier}
                />
              </RestrictedToPermissions>
            );
          })}
        </MuiList>
      </Collapse>
    </Fragment>
  );
}

type ProducerNavigationProps = {
  data: ProducerNavigationItemData[];
  applicablePermissions: Permission[];
  selectedItemKey?: string;
  automaticallyOpenParentOfSelected?: boolean;
};

export function ProducerNavigation({
  data,
  applicablePermissions,
  selectedItemKey,
  automaticallyOpenParentOfSelected,
}: ProducerNavigationProps): ReactElement {
  return (
    <MuiList component='nav'>
      {data.map((item) => (
        <ProducerNavigationListItemAndChildren
          {...item}
          onClick={item.onClick}
          key={item.identifier}
          level={0}
          selectedItemKey={selectedItemKey}
          automaticallyOpenParentOfSelected={automaticallyOpenParentOfSelected}
          applicablePermissions={applicablePermissions}
        />
      ))}
    </MuiList>
  );
}
