import { TranslateOptions } from 'i18n-js';
import { ReactNode, Component } from 'react';

import { logger } from '@breathelife/monitoring-frontend';

type Props = {
  renderErrorComponent?: () => ReactNode;
  textIfExists?: (value: string, parameters?: TranslateOptions) => string | undefined;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
  }

  render() {
    const { children, renderErrorComponent, textIfExists } = this.props;

    if (this.state.hasError && renderErrorComponent) {
      return renderErrorComponent();
    } else if (this.state.hasError && !renderErrorComponent) {
      const titleText = (textIfExists && textIfExists('errorBoundary.title')) ?? 'Oops, something went wrong!';
      const subtitleText =
        (textIfExists && textIfExists('errorBoundary.subtitle')) ??
        'We have been notified and are looking into the issue. Please refresh your browser or wait a few minutes.';
      return (
        <div style={{ marginTop: '40px', textAlign: 'center' }}>
          <h1>{titleText}</h1>
          <h2>{subtitleText}</h2>
        </div>
      );
    }

    return children;
  }
}
