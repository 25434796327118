import { HTMLAttributes, ReactNode, ReactElement, SetStateAction, Dispatch, CSSProperties, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

const DEFAULT_ARROW_WIDTH = '8px';
const DEFAULT_ARROW_COLOR = 'white';

export const PopperDefaultArrow = styled.div<{ arrowColor?: string; arrowWidth?: string }>`
  &::before {
    content: '';
    background: ${({ arrowColor }) => arrowColor || DEFAULT_ARROW_COLOR};
    width: ${({ arrowWidth }) => arrowWidth || DEFAULT_ARROW_WIDTH};
    height: ${({ arrowWidth }) => arrowWidth || DEFAULT_ARROW_WIDTH};
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const PopperElement = styled.div<{ isVisible: boolean; arrowWidth: string }>`
  display: inline-block;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  &[data-popper-placement^='top'] {
    [data-popper-arrow] {
      bottom: calc(${({ arrowWidth }) => arrowWidth} / 2);
      &::before {
        left: calc(-${({ arrowWidth }) => arrowWidth} / 2);
      }
    }
  }
  &[data-popper-placement^='left'] {
    [data-popper-arrow] {
      right: calc(${({ arrowWidth }) => arrowWidth} / 2);
      &::before {
        top: calc(-${({ arrowWidth }) => arrowWidth} / 2);
      }
    }
  }
  &[data-popper-placement^='right'] {
    [data-popper-arrow] {
      left: calc(-${({ arrowWidth }) => arrowWidth} / 2);
      &::before {
        top: calc(-${({ arrowWidth }) => arrowWidth} / 2);
      }
    }
  }
  &[data-popper-placement^='bottom'] {
    [data-popper-arrow] {
      top: calc(-${({ arrowWidth }) => arrowWidth} / 2);
      &::before {
        left: calc(-${({ arrowWidth }) => arrowWidth} / 2);
      }
    }
  }
`;

type PopperProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  anchorEl: HTMLElement;
  arrowColor?: string;
  arrowWidth?: string;
  offset?: number;
  placement: 'auto' | 'top' | 'bottom' | 'right' | 'left';
  customArrowElement?: boolean;
};

type PopperPropsWithoutTransition = PopperProps & {
  transition?: false;
  children?: ReactNode;
};

type PopperPropsWithTransition = PopperProps & {
  transition: true;
  children: (props: {
    arrowElementProps: {
      arrowColor?: string;
      arrowWidth?: string;
      'data-popper-arrow': boolean;
      ref: Dispatch<SetStateAction<HTMLDivElement | null>>;
      style: CSSProperties;
    };
    transitionProps: { in: boolean; onEnter: () => void; onExited: () => void };
  }) => ReactElement;
};

export function Popper(props: PopperPropsWithoutTransition | PopperPropsWithTransition): ReactElement | null {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const {
    children,
    anchorEl,
    isOpen,
    style,
    placement,
    arrowColor,
    arrowWidth = '8px',
    offset = 10,
    transition,
    customArrowElement,
    ...rest
  } = props;

  const [isVisibleWithTransition, setIsVisibleWithTransition] = useState<boolean>(false);

  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      { name: 'offset', options: { offset: [0, offset] } },
    ],
  });

  const arrowElementProps = {
    ...attributes.arrow,
    arrowColor,
    arrowWidth,
    'data-popper-arrow': true,
    ref: setArrowElement,
    style: styles.arrow,
  };

  const arrowElementMarkup = <PopperDefaultArrow {...arrowElementProps} />;

  const makeContent = (child: ReactNode | null, includeArrowElement: boolean): ReactElement => (
    <PopperElement
      {...attributes.popper}
      {...rest}
      isVisible={transition ? isVisibleWithTransition : isOpen}
      ref={setPopperElement}
      style={{ ...style, ...styles.popper }}
      arrowWidth={arrowWidth}
    >
      {child}
      {includeArrowElement && arrowElementMarkup}
    </PopperElement>
  );

  if (transition && typeof children === 'function') {
    return makeContent(
      children({
        arrowElementProps,
        transitionProps: {
          in: isOpen,
          onEnter: () => {
            setIsVisibleWithTransition(true);
          },
          onExited: () => {
            setIsVisibleWithTransition(false);
          },
        },
      }),
      false,
    );
  } else if (!transition && typeof children !== 'function') {
    return makeContent(children || null, !customArrowElement);
  }
  return null;
}
