import styled from 'styled-components';

import { Box, ListItem } from '@breathelife/mui';

const SPACE_BETWEEN_ICON_AND_TEXT = 8;
const BASE_ITEM_LEFT_PADDING = 24;
const SELECTED_LEFT_BAR_WIDTH = 4;
export const ICON_SIZE = 18;

export const StyledListItem = styled(ListItem)`
  padding: 8px;
  &.MuiListItem-root {
    transition: background-color 0.25s;
    &:hover {
      background-color: ${(props) => props.theme.colors.grey[30]};
    }
    &:active {
      background-color: ${(props) => props.theme.colors.grey[40]};
    }
    &.Mui-selected {
      background-color: ${(props) => props.theme.colors.grey[30]};
      &:hover {
        background-color: ${(props) => props.theme.colors.grey[30]};
      }
      &:active {
        background-color: ${(props) => props.theme.colors.grey[40]};
      }
    }
  }
`;

export const ListItemIconWithoutMinWidth = styled(Box)`
  min-width: 0;
  margin-right: ${SPACE_BETWEEN_ICON_AND_TEXT}px;
  display: inline-flex;
  flex-shrink: 0;
`;

export const ListItemContentWithLeftPadding = styled(Box)<{ level: number }>`
  padding-left: ${({ level }) => `${level * (SPACE_BETWEEN_ICON_AND_TEXT + ICON_SIZE) + BASE_ITEM_LEFT_PADDING}px`};
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 16px;
`;

export const SelectedListItemLeftBar = styled(Box)<{ visible: boolean }>`
  align-self: stretch;
  width: ${SELECTED_LEFT_BAR_WIDTH}px;
  transition: background-color 0.25s;
  background-color: ${(props) => (props.visible ? props.theme.colors.primary.default : 'transparent')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;
