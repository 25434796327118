import styled from 'styled-components';

import { Button, IconButton } from '@breathelife/mui';

export const ModalButton = styled(Button)`
  && {
    margin: 0 10px;
    padding: 10px 15px;
    min-width: 120px;

    .MuiButton-label {
      display: flex;
      gap: 4px;
    }
  }
`;

export const ModalButtonClose = styled(IconButton)`
  && {
    position: absolute;
    right: 5px;
    top: 5px;
    border: 1px solid transparent;

    &:hover {
      background-color: transparent;
    }
    :focus {
      border-color: ${(props) => props.theme.colors.selection.border.focus};
    }
  }
`;
